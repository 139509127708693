<template>
  <div class="row">
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-12">
          <h3 class="fw-bolder my-2 text-blue">Formulario crear informes</h3>
        </div>
      </div>
      <Form @submit="onSubmit" :validation-schema="schema">
        <div class="row">
          <div class="col-md-12 mb-2">
            <input
              @keyup="customFilter($event.target.value)"
              v-model="dispositivoFilter"
              type="text"
              class="form-control"
              placeholder="Añade uno o mas dispositivos"
            />
          </div>
          <div class="col-6">
            <small
              >Dispositivos agregados: {{ model.dispositivos.length }}
            </small>
            <div class="invalid-feedback d-flex">{{ errores.dispositivo }}</div>
          </div>
          <div class="col-6 text-end">
            <button
              type="button"
              class="btn btn-sm btn-primary"
              @click="addDispositivos"
            >
              Agregar
            </button>
          </div>
          <div class="col-md-12">
            <div
              class="row p-1 mt-1 mx-0 bg-light"
              v-for="(dispositivo, k) in model.dispositivos"
              :key="k"
            >
              <div class="col-10 d-flex">
                <i class="icon-mail text-primary mr-2"></i>
                <span>
                  {{ nameDispositivo(dispositivo) }}
                </span>
              </div>
              <div class="col-2 text-end">
                <span
                  class="badge badge-pill badge-danger cr-pointer"
                  @click="removeDispositivos(k)"
                >
                  X
                </span>
              </div>
            </div>
            <div
              v-show="dispositivosFiltrados.length"
              class="py-3"
              style="height: 300px; overflow-y: auto"
            >
              <div
                @click="
                  dispositivo.selected = dispositivo.selected ? false : true
                "
                v-for="(dispositivo, k) in dispositivosFiltrados"
                :key="k"
                :class="`row mt-1 ${
                  dispositivo.selected ? 'bg-light-primary' : ''
                } p-2`"
                style="cursor: pointer"
              >
                <div class="col-10">
                  <i class="text-info">
                    <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                      <path
                        fill="currentColor"
                        d="M15 13V5A3 3 0 0 0 9 5V13A5 5 0 1 0 15 13M12 4A1 1 0 0 1 13 5V12H11V5A1 1 0 0 1 12 4Z"
                      />
                    </svg>
                  </i>
                  <small> {{ dispositivo.nombre }}</small>
                </div>
                <div class="col-2 text-lg-end">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    v-model="dispositivo.selected"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <label for="nombre">nombre</label>
            <Field
              name="nombre"
              type="text"
              class="form-control"
              v-model="model.nombre"
            />
            <ErrorMessage name="nombre" class="text-danger" />
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <label for="hora">Hora</label>
            <Field
              name="hora"
              type="time"
              class="form-control"
              v-model="model.hora"
            />
            <ErrorMessage name="hora" class="text-danger" />
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <p for="">Seleccionar días</p>
            <div
              @click="selectedDia(dia, k)"
              v-for="(dia, k) in dias"
              :key="k"
              :class="`btn btn-icon ${
                dia.selected ? 'btn-primary' : 'btn-secondary'
              } w-40px h-40px pulse pulse-white m-lg-1`"
            >
              <span>{{ dia.text }}</span>
            </div>
            <div class="invalid-feedback d-flex">{{ errores.dia }}</div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 mb-2">
            <input
              type="email"
              class="form-control"
              v-model="emailInsert"
              placeholder="Correo electrónico"
            />
          </div>
          <div class="col-6">
            <small>Correos agregados: {{ model.correos.length }} </small>
            <div class="invalid-feedback d-flex">{{ errores.correos }}</div>
          </div>
          <div class="col-6 text-end">
            <button
              type="button"
              class="btn btn-sm btn-primary"
              @click="addEmail"
            >
              Agregar
            </button>
          </div>
        </div>
        <div
          class="row p-1 mt-1 mx-0 bg-light"
          v-for="(correo, k) in model.correos"
          :key="k"
        >
          <div class="col-10 d-flex">
            <i class="icon-mail text-primary mr-2"></i>
            <span>
              {{ correo }}
            </span>
          </div>
          <div class="col-2 text-end">
            <span
              class="badge badge-pill badge-danger cr-pointer"
              @click="removeEmail(k)"
            >
              X
            </span>
          </div>
        </div>
        <div class="row justify-content-end mt-5">
          <div class="col-md-4 text-end">
            <button name="button" class="btn btn-primary btn-sm w-100">
              Guardar
            </button>
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import { alerta } from "@/utils/mensajes";
import {
  // getDispositivosFiltrar,
  getDispositivos,
} from "@/services/dispositivos";
import {
  crearReporte,
  actualizarReporte,
  getReportesId,
} from "@/services/informes";
import { useRoute, useRouter } from "vue-router";
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Field, Form, ErrorMessage } from "vee-validate";
import * as yup from "yup";

export default defineComponent({
  name: "FormularioRegistro",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Documents", ["Pages", "Profile"]);
      fetchInformes();
    });
    const fetchInformes = () => {
      getDispositivos()
        .then(({ data }) => {
          dispositivosEncontrados = data;
          if (route.params._id) {
            getReportesId(route.params._id)
              .then(({ data }) => {
                model.value = data;
                for (const dia of model.value.dias.split(",")) {
                  let indx = dias.value.findIndex((f) => f.value == dia);
                  dias.value[indx].selected = true;
                }
              })
              .catch((err) => {
                console.error(err);
              });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    };
    let dispositivosEncontrados = [];
    const route = useRoute();
    const router = useRouter();
    const dispositivosFiltrados = ref([]);
    const dispositivoFilter = ref([]);
    const emailInsert = ref("");
    const model = ref({
      nombre: "",
      hora: "",
      dias: "",
      correos: [],
      dispositivos: [],
    });
    const errores = ref({
      dispositivo: "",
      dia: "",
      correos: "",
    });
    const dias = ref([
      { value: 1, text: "Lun", selected: false },
      { value: 2, text: "Mar", selected: false },
      { value: 3, text: "Mie", selected: false },
      { value: 4, text: "Jue", selected: false },
      { value: 5, text: "Vie", selected: false },
      { value: 6, text: "Sab", selected: false },
      { value: 7, text: "Dom", selected: false },
    ]);
    const schema = yup.object({
      nombre: yup.string().required("nombre es requerido"),
      hora: yup.string().required("hora es requerido"),
    });
    const validateAgg = (data, campo) => {
      return new Promise((resolve) => {
        errores.value[campo] = data.length
          ? ""
          : `Mínimo un ${campo} requerido`;
        resolve(data.length ? true : false);
      });
    };
    const validateForm = () => {
      return new Promise((resolve) => {
        Promise.all([
          validateAgg(model.value.dispositivos, "dispositivo"),
          validateAgg(model.value.dias, "dia"),
          validateAgg(model.value.correos, "correos"),
        ]).then((res) => {
          resolve(res.filter((f) => !f).length ? false : true);
        });
      });
    };
    const resetForm = () => {
      model.value = {
        nombre: "",
        hora: "",
        dias: "",
        correos: [],
        dispositivos: [],
      };
      errores.value = {
        dispositivo: "",
        dia: "",
        correos: "",
      };
    };
    const onSubmit = async () => {
      const esValidoForm = await validateForm();
      if (!esValidoForm) return;
      if (route.params._id) {
        actualizarReporte(route.params._id, model.value)
          .then(({ data, status }) => {
            if (status == 200) {
              alerta(
                "Mensaje",
                data.message ? data.message : "actualizado correctamente",
                "success"
              );
              router.back();
            }
          })
          .catch((err) => {
            console.error(err);
          });
      } else {
        crearReporte(model.value)
          .then(({ data, status }) => {
            if (status == 201) {
              alerta(
                "Mensaje",
                data.message ? data.message : "creado correctamente",
                "success"
              );
              resetForm();
              router.back();
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    };
    const customFilter = (query) => {
      if (query) {
        let myReg = new RegExp(query.toLowerCase() + ".*");
        dispositivosFiltrados.value = dispositivosEncontrados.filter((f) =>
          f.nombre.toLowerCase().match(myReg)
        );
      } else {
        dispositivosFiltrados.value = [];
      }
    };
    const addDispositivos = () => {
      for (const dispositivo of dispositivosFiltrados.value.filter(
        (f) => f.selected
      )) {
        let dispositivoFind = model.value.dispositivos.find(
          (f) => f == dispositivo._id
        );
        if (!dispositivoFind) {
          model.value.dispositivos.push(dispositivo._id);
        }
      }
      dispositivoFilter.value = "";
      dispositivosFiltrados.value = [];
    };
    const removeDispositivos = (key) => {
      model.value.dispositivos.splice(key, 1);
    };
    const selectedDia = (dia, key) => {
      dias.value[key].selected = dias.value[key].selected ? false : true;
      model.value.dias = dias.value
        .filter((f) => f.selected)
        .map((e) => e.value)
        .join(",");
    };
    const addEmail = () => {
      const atlength = emailInsert.value.split("@").length;
      const atposition = emailInsert.value.indexOf("@");
      const dotposition = emailInsert.value.lastIndexOf(".");
      let i = 0;
      errores.value.correos = "";
      if (!emailInsert.value) {
        errores.value.correos = "El correo es requerido";
        i++;
      } else {
        if (
          atposition < 1 ||
          dotposition < atposition + 2 ||
          dotposition + 2 >= emailInsert.value.length ||
          atlength > 2
        ) {
          errores.value.correos = "El correo no es valido";
          i++;
        } else if (model.value.correos.find((f) => f == emailInsert.value)) {
          errores.value.correos = "El correo ya fue ingresado";
          i++;
        }
      }
      if (i) {
        return false;
      }
      model.value.correos.push(JSON.parse(JSON.stringify(emailInsert.value)));
      emailInsert.value = "";
    };
    const removeEmail = (key) => {
      model.value.correos.splice(key, 1);
    };
    const nameDispositivo = (dispositivoId) => {
      let dispositivoFind = dispositivosEncontrados.find(
        (f) => f._id == dispositivoId
      );
      return dispositivoFind ? dispositivoFind.nombre : "";
    };
    return {
      dispositivoFilter,
      model,
      errores,
      emailInsert,
      dias,
      schema,
      dispositivosFiltrados,
      onSubmit,
      customFilter,
      addEmail,
      removeEmail,
      addDispositivos,
      removeDispositivos,
      selectedDia,
      nameDispositivo,
    };
  },
});
</script>
